.newPlans {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  background-color: #e8ebf1;
}

.newPlans > h1 {
  font-size: 1.5rem;
  text-align: center;
  color: var(--orange);
}

.newPlans__cardsContainer {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  max-width: 700px;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

@media (max-width: 625px) {
  .newPlans__cardsContainer {
    flex-direction: column;
    align-items: center;
  }

  .newPlans > h1 {
    font-size: 1rem;
  }

  .newPlans__cardsContainer > * {
    margin-bottom: 20px !important;
  }
}
