.firstButtonFirstSection {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  width: 230px;
  height: 40px;
  cursor: pointer;
  background-color: var(--secondary);
}

.firstButtonFirstSection > span {
  font-weight: 600;
  font-size: 0.8rem;
  color: black;
  /* text-transform: capitalize; */
}

.firstButtonFirstSection:hover {
  filter: brightness(1.1);
}

.semMargin {
  margin-top: 0 !important;
  margin-right: 5px;
}

.bodyButton__backgroundWhiteColorBlack {
  margin-top: 15px;
  margin-bottom: 40px;
  background-color: white;
}

.bodyButton__backgroundWhiteColorBlack > span {
  color: black;
}

.bodyButton__backgroundWhiteColorBlack:hover {
  background-color: var(--branco-cinza);
}

.bodyButton__backgroundDarkPink {
  color: white !important;
}

@media (min-width: 450px) {
  .firstButtonFirstSection {
    width: 250px;
  }

  .firstButtonFirstSection > span {
    font-size: 0.9rem;
  }
}

@media (min-width: 500px) {
  .firstButtonFirstSection {
    width: 270px;
    height: 45px;
  }

  .firstButtonFirstSection > span {
    font-size: 1rem;
  }
}

@media (min-width: 600px) {
  .firstButtonFirstSection {
    width: 300px;
    height: 50px;
  }

  .firstButtonFirstSection > span {
    font-size: 1.1rem;
  }
}

@media (min-width: 850px) {
  .firstButtonFirstSection {
    width: 210px;
    height: 38px;
  }

  .firstButtonFirstSection > span {
    font-size: 0.8rem;
  }

  .bodyButton__backgroundWhiteColorBlack {
    margin-bottom: 0;
  }
}

@media (min-width: 1000px) {
  .firstButtonFirstSection {
    width: 230px;
    height: 42px;
  }

  .firstButtonFirstSection > span {
    font-size: 0.9rem;
  }

  .semMargin {
    margin-right: 10px;
  }
}

@media (min-width: 1100px) {
  .firstButtonFirstSection {
    width: 250px;
    height: 45px;
  }

  .firstButtonFirstSection > span {
    font-size: 1rem;
  }

  .semMargin {
    margin-right: 15px;
  }
}

@media (min-width: 1200px) {
  .firstButtonFirstSection {
    width: 270px;
    height: 47px;
  }

  .semMargin {
    margin-right: 20px;
  }
}

@media (min-width: 1300px) {
  .firstButtonFirstSection {
    width: 300px;
    height: 50px;
  }

  .firstButtonFirstSection > span {
    font-size: 1.1rem;
  }
}

@media (min-width: 1400px) {
  .firstButtonFirstSection {
    width: 320px;
  }
}

@media (min-width: 1500px) {
  .firstButtonFirstSection {
    width: 350px;
    height: 55px;
    border-radius: 30px;
  }

  .firstButtonFirstSection > span {
    font-size: 1.2rem;
  }
}

@media (min-width: 1700px) {
  .firstButtonFirstSection > span {
    font-size: 1.3rem;
  }
}

@media (min-width: 1800px) {
  .firstButtonFirstSection {
    width: 400px;
    height: 60px;
    border-radius: 35px;
  }

  .firstButtonFirstSection > span {
    font-size: 1.4rem;
  }
}
