@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900");

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --dark-red: #ac4134;
  --dark-green: #288a3d;
  --orange: #ff7700;
  --green: #38b152;
  --red: #cf5c44;
  --gray: #6b7880;
  --light-gray: #c0c2c9;
  --main-dark: #262e43;
  --main-light: #35415e;
  --darker: #191f2d;
  --laranja: #eba030;
  --laranja-secundario: #ed9f2e;
  --azul-marinho: #000b48;
  --azul-claro: #0978c5;
  --azul-agua: #3b8cbc;
  --cinza: #3b4460;
  --marrom: #5b2513bf;
  --verde: #3fbf63bb;
  --vermelho: #ed3e3edc;
  --branco-cinza: rgb(205, 205, 214);

  --vermelho: rgb(252, 80, 80);
  --verde: rgb(35, 210, 35);
  --vermelho-transparente: rgba(252, 80, 80, 0.4);
  --verde-transparente: rgba(35, 210, 35, 0.4);
  --roxo: #937cfa;
  --roxo-transparente: rgba(0, 0, 0, 0.7);
  --hoverSelect: rgb(255, 240, 246);

  --primary: black;
  --secondary: rgb(255, 230, 0);
  --tertiary: yellow;
  --gray: rgb(168, 167, 167);
  --grayInputs: #838586;
  --gray-transparent: rgba(168, 167, 167, 0.4);

  --black-gradient: linear-gradient(
    90deg,
    rgba(18, 18, 18, 1) 0%,
    rgba(4, 4, 4, 1) 35%,
    rgba(107, 109, 110, 1) 100%
  );
  --black: rgb(18, 18, 18);
  --black-gradient-100: linear-gradient(
    90deg,
    rgba(18, 18, 18, 1) 0%,
    rgba(40, 40, 40, 1) 35%,
    rgba(107, 109, 110, 1) 100%
  );
}

a {
  text-decoration: none;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.ta-j {
  text-align: justify;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #99acb8;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #acc1ce;
}

a,
a:link,
a:hover,
a:focus,
a:active,
a:visited {
  text-decoration: none;
  color: inherit;
}
