.fridaySection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(239, 242, 246);
    width: 100%;
    padding-bottom: 30px;
}

.fridaySection__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.15rem;
    font-weight: 300;
    width: 90%;
    margin: 20px 0;
}

.fridaySection__comNegrito {
    margin-bottom: 10px;
    margin-left: 5px;
    font-weight: 500;
    font-size: 1.2rem;
}

.fridaySection__mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.fridaySection__desktop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1700px;
}


@media (min-width:400px) {
    .fridaySection__title {
        font-size: 1.3rem;
        width: 88%;
    }

    .fridaySection__comNegrito {
        font-size: 1.4rem;
    }
}

@media (min-width:500px) {
    .fridaySection__title {
        font-size: 1.4rem;
        width: 85%;
    }

    .fridaySection__comNegrito {
        font-size: 1.5rem;
    }
}

@media (min-width:600px) {
    .fridaySection__title {
        font-size: 1.5rem;
        width: 80%;
    }

    .fridaySection__comNegrito {
        font-size: 1.6rem;
    }
}

@media (min-width:700px) {
    .fridaySection__title {
        font-size: 1.6rem;
        width: 75%;
    }

    .fridaySection__comNegrito {
        font-size: 1.7rem;
    }
}

@media (min-width:800px) {
    .fridaySection__title {
        font-size: 1.7rem;
        width: 70%;
    }

    .fridaySection__comNegrito {
        font-size: 1.8rem;
    }
}

@media (min-width:850px) {
    .fridaySection__mobile {
        display: none;
    }
}

@media (max-width:850px) {
    .fridaySection__desktop {
        display: none;
    }
}

@media (min-width:900px) {
    .fridaySection__title {
        margin: 30px 0;
    }

    .fridaySection__comNegrito {
        margin-bottom: 15px;
    }
}

@media (min-width:1000px) {
    .fridaySection__title {
        font-size: 1.8rem;
    }

    .fridaySection__comNegrito {
        font-size: 1.9rem;
    }
}

@media (min-width:1200px) {

    .fridaySection__title {
        font-size: 1.9rem;
        margin: 40px 0;
        margin-bottom: 50px;
        width: 60%;
    }

    .fridaySection__comNegrito {
        font-size: 2rem;
    }

}

@media (min-width:1400px) {

    .fridaySection {
        padding-bottom: 60px;
    }

    .fridaySection__title {
        font-size: 2rem;
        width: 55%;
    }

    .fridaySection__comNegrito {
        font-size: 2.1rem;
    }

    .fridaySection__desktop {
        width: 90%;
    }

}

@media (min-width:1600px) {

    .fridaySection__title {
        font-size: 2.1rem;
        width: 50%;
    }

    .fridaySection__comNegrito {
        font-size: 2.2rem;
    }

    .fridaySection__desktop {
        width: 85%;
    }

    .fridaySection {
        padding-bottom: 80px;
    }

}

@media (min-width:1800px) {

    .fridaySection__title {
        font-size: 2.2rem;
        width: 45%;
    }

    .fridaySection__comNegrito {
        font-size: 2.3rem;
    }

}

@media (min-width:1900px) {

    .fridaySection__title {
        font-size: 2.3rem;
    }

    .fridaySection__comNegrito {
        font-size: 2.4rem;
    }
}

@media (min-width:2000px) {

    .fridaySection__title {
        font-size: 2.4rem;
        width: 40%;
    }

    .fridaySection__comNegrito {
        font-size: 2.5rem;
    }

}