.fridaySectionCard {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    margin-bottom: 20px;
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 0 10px 2px rgba(128, 128, 128, 0.3);
}

.fridaySectionCard__img {
    width: 100%;
    object-fit: cover;
}

.fridaySectionCard__textContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 30px 0;
}

.fridaySectionCard__textContainer_title {
    font-size: 1.2rem;
    font-weight: 500;
    width: 85%;
    margin-bottom: 10px;
    color: rgb(55, 55, 55);
}

.fridaySectionCard__textContainer_text {
    font-size: 0.8rem;
    color: gray;
    width: 85%;
}

@media (min-width:400px) {

    .fridaySectionCard {
        margin-bottom: 25px;
    }

    .fridaySectionCard__textContainer_title {
        font-size: 1.3rem;
        margin-bottom: 15px;
    }

    .fridaySectionCard__textContainer_text {
        font-size: 0.9rem;
    }

}

@media (min-width:500px) {

    .fridaySectionCard {
        margin-bottom: 30px;
    }

    .fridaySectionCard__textContainer {
        padding: 40px 0;
    }

    .fridaySectionCard__textContainer_title {
        font-size: 1.4rem;
    }

    .fridaySectionCard__textContainer_text {
        font-size: 1rem;
    }

}

@media (min-width:600px) {

    .fridaySectionCard {
        margin-bottom: 35px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .fridaySectionCard__textContainer {
        padding: 50px 0;
    }

    .fridaySectionCard__textContainer_title {
        font-size: 1.5rem;
    }

    .fridaySectionCard__textContainer_text {
        font-size: 1.1rem;
    }

}

@media (min-width:700px) {

    .fridaySectionCard {
        margin-bottom: 40px;
    }

    .fridaySectionCard__textContainer_title {
        font-size: 1.6rem;
        margin-bottom: 20px;
    }

    .fridaySectionCard__textContainer_text {
        font-size: 1.2rem;
    }

}

@media (min-width:800px) {

    .fridaySectionCard {
        margin-bottom: 45px;
    }

    .fridaySectionCard__textContainer {
        padding: 60px 0;
    }

    .fridaySectionCard__textContainer_title {
        font-size: 1.7rem;
    }

    .fridaySectionCard__textContainer_text {
        font-size: 1.3rem;
    }

}


@media (min-width:850px) {

    .fridaySectionCard {
        margin-bottom: 0;
        width: 32%;
        height: 640px;
    }

    .fridaySectionCard__textContainer_title {
        font-size: 1.3rem;
        margin-bottom: 15px;
    }

    .fridaySectionCard__textContainer_text {
        font-size: 0.9rem;
    }

    .fridaySectionCard__img {
        height: 30%;
    }

    .fridaySectionCard__textContainer {
        height: 70%;
        padding: 0;
        padding-top: 30px;
    }

}

@media (min-width:900px) {

    .fridaySectionCard {
        height: 700px;
    }

    .fridaySectionCard__textContainer_title {
        font-size: 1.4rem;
    }

    .fridaySectionCard__textContainer_text {
        font-size: 1rem;
    }

}

@media (min-width:1000px) {

    .fridaySectionCard {
        height: 760px;
    }

    .fridaySectionCard__textContainer_title {
        font-size: 1.5rem;
    }

    .fridaySectionCard__textContainer_text {
        font-size: 1.1rem;
    }

}

@media (min-width:1100px) {

    .fridaySectionCard {
        height: 840px;
    }

    .fridaySectionCard__textContainer {
        padding-top: 40px;
    }

    .fridaySectionCard__textContainer_title {
        font-size: 1.6rem;
        margin-bottom: 20px;
    }

    .fridaySectionCard__textContainer_text {
        font-size: 1.2rem;
    }

}

@media (min-width:1200px) {

    .fridaySectionCard {
        height: 950px;
    }

    .fridaySectionCard__img {
        height: 35%;
    }

    .fridaySectionCard__textContainer {
        height: 65%;
    }

    .fridaySectionCard__textContainer_title {
        font-size: 1.7rem;
    }

    .fridaySectionCard__textContainer_text {
        font-size: 1.3rem;
    }

}

@media (min-width:1300px) {

    .fridaySectionCard {
        height: 920px;
    }

}

@media (min-width:1400px) {

    .fridaySectionCard {
        height: 990px;
    }

    .fridaySectionCard__img {
        height: 40%;
    }

    .fridaySectionCard__textContainer {
        height: 60%;
    }

}

@media (min-width:1500px) {

    .fridaySectionCard {
        height: 940px;
    }

}

@media (min-width:1600px) {

    .fridaySectionCard {
        height: 1010px;
    }

    .fridaySectionCard__img {
        height: 45%;
    }

    .fridaySectionCard__textContainer {
        height: 55%;
    }

}

@media (min-width:1700px) {

    .fridaySectionCard {
        height: 920px;
    }

}

@media (min-width:1850px) {

    .fridaySectionCard {
        height: 970px;
    }

    .fridaySectionCard__textContainer_title {
        font-size: 1.8rem;
    }

    .fridaySectionCard__textContainer_text {
        font-size: 1.4rem;
    }
}

@media (min-width:2000px) {

    .fridaySectionCard {
        height: 1030px;
    }

    .fridaySectionCard__textContainer_title {
        font-size: 1.9rem;
    }

    .fridaySectionCard__textContainer_text {
        font-size: 1.5rem;
    }
}