.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* box-shadow: -1px 20px 20px -6px rgba(11, 185, 185, 0.9); */
  color: rgba(0, 0, 0, 1);
}

.header__logo {
  font-size: 1.5rem;
  background-color: white;
  border: 1px solid black;
  padding: 5px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.header__right {
  display: flex;
  font-size: 1rem;
}

.header__signIn {
  margin-right: 10px;
  cursor: pointer;
}

.header__signUp {
  color: black;
  cursor: pointer;
  margin-right: 10px;
  font-weight: 600;
}

.header__signIn:hover {
  color: gray;
}

.header__signUp:hover {
  filter: brightness(1.3);
  opacity: 0.8;
}

.header__logoContainer {
  display: flex;
}

.header__logoO {
  color: var(--secondary);
  filter: brightness(2.2);
  font-size: 1.5rem;
  background: rgba(0, 0, 0, 0.8);
  padding: 5px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-weight: 500;
}

.header > img {
  object-fit: contain;
  height: 40px;
  padding-top: 8px;
}

@media (max-width: 500px) {
  .header__logoO {
    font-size: 1rem;
  }

  .header__logo {
    font-size: 1rem;
  }
}
