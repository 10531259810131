.videoSection > span {
  width: 100%;
  text-align: center;
}

.videoSection {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-self: center !important;
  margin: 20px 0;
  padding: 40px 0;
}

.videoSection__carousel {
  padding: 0;
  margin: 0;
}

@media (max-width: 625px) {
  .videoSection > h1 {
    font-size: 1rem;
  }
}
