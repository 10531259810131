.footer {
  display: flex;
  align-items: center !important;
  justify-content: space-around !important;
  background: var(--black);
  background: var(--black-gradient);
  color: white;
  padding: 30px 0;
}

.footer__left > .footer__leftImg > img {
  height: 150px;
  object-fit: contain;
  cursor: pointer;
  border-radius: 10px;
}

.footer__left > .footer__leftImg > img:hover {
  filter: brightness(1.1);
  opacity: 0.9;
  filter: contrast(1.1);
}

.footer__left {
  display: flex;
  flex-direction: column;
}

.footer_bottom {
  margin-top: 5px;
}
.footer_bottom > span {
  font-size: small;
  text-decoration: underline;
  opacity: 0.7;
  cursor: pointer;
}

.footer_bottom > span:hover {
  opacity: 1;
}

@media (max-width: 1320px) {
  .footer {
    flex-direction: column-reverse;
    padding-left: 10px;
    padding-right: 10px;
  }

  .footer__left {
    display: flex;
    flex-direction: column-reverse !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .footer__left > * {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    padding-bottom: 10px;
  }

  .footer__rightSocial > * {
    font-size: 2rem !important;
  }

  .footer__right {
    align-items: center;
  }

  .footer__left {
    align-items: center;
    padding-left: 0 !important;
    font-size: 1rem !important;
    padding-top: 20px;
  }
  .footer__row {
    width: 230px;
  }

  .footer__right > p {
    max-width: 350px;
  }
}

.footer > * {
  /* flex-basis: 100% !important; */
  justify-content: center;
}

.footer__left {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
}

.footer__row {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.footer__rowIcon {
  display: flex;
  margin-right: 10px;
  background-color: gray;
  align-content: center !important;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
}

.footer__rowIcon:hover {
  filter: brightness(1.5);
}

.footer__rowDescription {
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .footer__rowDescription {
    font-size: small;
  }
}

.footer__rowDescription {
  font-size: 0.75rem;
}

.footer__right {
  display: flex;
  flex-direction: column;
}

.footer__right > h1 {
  font-size: 1.3rem;
  margin: 0;
  padding-bottom: 5px;
}

.footer__right > p {
  font-size: 0.7rem;
  color: var(--light-gray);
  max-width: 500px;
  margin: 0;
  padding-bottom: 10px;
}

.footer__rightSocial {
  display: flex;
  align-items: center;
}

.footer__rightSocial > a > * {
  display: flex;
  margin-right: 5px;
  background-color: gray;
  align-content: center !important;
  padding: 5px;
  border-radius: 5px;
  font-size: 2rem !important;
  cursor: pointer;
}

.footer__rightSocial > a > *:hover {
  filter: brightness(1.5);
}
