.firstSectionDesktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.firstSectionDesktop__top {
  display: flex;
}

.firstSectionDesktop__topTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 50px;
  width: 50%;
  height: 300px;
}

.firstSectionDesktop__topTextTitle {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 10px;
}

.semNegrito {
  margin-left: 5px;
  font-weight: 300;
}

.firstSectionDesktop__topText {
  font-size: 0.9rem;
  font-weight: 300;
  color: var(--grayInputs);
}

.firstSectionDesktop__topDivVazia {
  width: 50%;
  position: relative;
}

.firstSectionDesktop__bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--black);
  background: var(--black-gradient);
  width: 100%;
}

.firstSectionDesktop__buttonsContainer {
  display: flex;
  width: 100%;
  padding: 25px 0;
  padding-bottom: 15px;
}

.firstSectionDesktop__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-left: 40px;
}

.firstSectionDesktop__imgContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: -20px;
  left: 0;
}

.firstSectionDesktop__icons {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -50px;
  margin-left: -110px;
}

.firstSectionDesktop__imgLampada {
  object-fit: contain;
  width: 60px;
  margin-top: 80px;
}

.firstSectionDesktop__imgAviao {
  object-fit: contain;
  width: 60px;
  margin-bottom: 50px;
}

.firstSectionDesktop__imgBonequinhos {
  object-fit: contain;
  width: 60px;
  margin-top: 80px;
}

.firstSectionDesktop__imgMenina {
  object-fit: contain;
  width: 400px;
}

.firstSectionDesktop__bottomCorte {
  margin-bottom: -1px;
  margin-left: -1px;
  margin-right: -1px;
}

@media (max-width: 849px) {
  .firstSectionDesktop {
    display: none;
  }
}

@media (min-width: 900px) {
  .firstSectionDesktop__imgContainer {
    top: 0;
  }

  .firstSectionDesktop__icons {
    margin-bottom: -60px;
    margin-left: -100px;
  }

  .firstSectionDesktop__topTextContainer {
    padding: 0 50px;
  }

  .firstSectionDesktop__topText {
    font-size: 1rem;
  }
}

@media (min-width: 1000px) {
  .firstSectionDesktop__topTextTitle {
    font-size: 1.7rem;
  }

  .firstSectionDesktop__topText {
    font-size: 1.1rem;
  }

  .firstSectionDesktop__imgContainer {
    left: 20px;
    top: -10px;
  }

  .firstSectionDesktop__icons {
    margin-left: -120px;
  }

  .firstSectionDesktop__imgMenina {
    width: 425px;
  }

  .firstSectionDesktop__buttons {
    margin-top: 10px;
  }
}

@media (min-width: 1200px) {
  .firstSectionDesktop__imgContainer {
    left: 30px;
    top: 10px;
  }

  .firstSectionDesktop__topTextTitle {
    font-size: 1.8rem;
  }

  .firstSectionDesktop__topText {
    font-size: 1.2rem;
  }

  .firstSectionDesktop__imgLampada {
    width: 65px;
  }

  .firstSectionDesktop__imgAviao {
    width: 65px;
  }

  .firstSectionDesktop__imgBonequinhos {
    width: 65px;
  }

  .firstSectionDesktop__imgMenina {
    width: 475px;
  }

  .firstSectionDesktop__topTextContainer {
    padding: 0 60px;
    height: 350px;
  }
}

@media (min-width: 1300px) {
  .firstSectionDesktop__imgContainer {
    top: -10px;
  }

  .firstSectionDesktop__topTextTitle {
    font-size: 1.9rem;
  }

  .firstSectionDesktop__topText {
    font-size: 1.3rem;
  }

  .firstSectionDesktop__imgLampada {
    width: 70px;
  }

  .firstSectionDesktop__imgAviao {
    width: 70px;
  }

  .firstSectionDesktop__imgBonequinhos {
    width: 70px;
  }

  .firstSectionDesktop__imgMenina {
    width: 500px;
  }
}

@media (min-width: 1400px) {
  .firstSectionDesktop__topTextContainer {
    height: 400px;
  }

  .firstSectionDesktop__topTextTitle {
    font-size: 2rem;
  }

  .firstSectionDesktop__topText {
    font-size: 1.4rem;
  }

  .firstSectionDesktop__buttons {
    margin-left: 45px;
  }

  .firstSectionDesktop__imgLampada {
    width: 75px;
  }

  .firstSectionDesktop__imgAviao {
    width: 75px;
  }

  .firstSectionDesktop__imgBonequinhos {
    width: 75px;
  }

  .firstSectionDesktop__imgContainer {
    top: 40px;
  }
}

@media (min-width: 1500px) {
  .firstSectionDesktop__imgContainer {
    top: 20px;
  }

  .firstSectionDesktop__icons {
    margin-left: -150px;
  }

  .firstSectionDesktop__topTextTitle {
    font-size: 2.1rem;
  }

  .firstSectionDesktop__imgMenina {
    width: 550px;
  }
}

@media (min-width: 1600px) {
  .firstSectionDesktop__imgContainer {
    left: 60px;
    top: 0;
  }

  .firstSectionDesktop__topTextTitle {
    font-size: 2.2rem;
  }

  .firstSectionDesktop__topText {
    font-size: 1.5rem;
  }

  .firstSectionDesktop__icons {
    width: 90%;
    margin-bottom: -70px;
    margin-left: -170px;
  }

  .firstSectionDesktop__imgLampada {
    width: 80px;
  }

  .firstSectionDesktop__imgAviao {
    width: 80px;
  }

  .firstSectionDesktop__imgBonequinhos {
    width: 80px;
  }

  .firstSectionDesktop__imgMenina {
    width: 575px;
  }

  .firstSectionDesktop__buttons {
    margin-left: 50px;
  }

  .firstSectionDesktop__topTextContainer {
    padding: 0 80px;
    height: 400px;
  }
}

@media (min-width: 1700px) {
  .firstSectionDesktop__imgContainer {
    left: 40px;
    top: -10px;
  }

  .firstSectionDesktop__imgLampada {
    width: 90px;
  }

  .firstSectionDesktop__imgAviao {
    width: 90px;
  }

  .firstSectionDesktop__imgBonequinhos {
    width: 90px;
  }

  .firstSectionDesktop__imgMenina {
    width: 600px;
  }

  .firstSectionDesktop__buttons {
    margin-left: 50px;
  }

  .firstSectionDesktop__topTextContainer {
    padding: 0 80px;
    height: 400px;
  }

  .firstSectionDesktop__topTextTitle {
    font-size: 2.4rem;
  }

  .firstSectionDesktop__topText {
    font-size: 1.5rem;
  }

  .firstSectionDesktop__icons {
    width: 90%;
    margin-bottom: -70px;
    margin-left: -170px;
  }

  .firstSectionDesktop__topTextContainer {
    padding: 0 100px;
    height: 420px;
  }
}

@media (min-width: 1800px) {
  .firstSectionDesktop__topTextTitle {
    font-size: 2.5rem;
  }

  .firstSectionDesktop__topText {
    font-size: 1.6rem;
  }

  .firstSectionDesktop__icons {
    margin-bottom: -80px;
    margin-left: -180px;
  }

  .firstSectionDesktop__buttons {
    margin-left: 55px;
  }

  .firstSectionDesktop__imgMenina {
    width: 650px;
  }

  .firstSectionDesktop__imgContainer {
    top: -20px;
  }
}

@media (min-width: 1900px) {
  .firstSectionDesktop__icons {
    width: 85%;
  }

  .firstSectionDesktop__buttons {
    margin-left: 65px;
  }

  .firstSectionDesktop__topTextTitle {
    font-size: 2.6rem;
  }

  .firstSectionDesktop__topText {
    font-size: 1.7rem;
  }
}

@media (min-width: 2000px) {
  .firstSectionDesktop__buttons {
    margin-left: 55px;
  }

  .firstSectionDesktop__topTextTitle {
    font-size: 2.7rem;
  }

  .firstSectionDesktop__topText {
    font-size: 1.8rem;
  }

  .firstSectionDesktop__topTextContainer {
    padding: 0 120px;
    height: 450px;
  }

  .firstSectionDesktop__icons {
    margin-bottom: -100px;
    margin-left: -220px;
  }

  .firstSectionDesktop__imgLampada {
    width: 100px;
  }

  .firstSectionDesktop__imgAviao {
    width: 100px;
    margin-bottom: 90px;
  }

  .firstSectionDesktop__imgBonequinhos {
    width: 100px;
  }

  .firstSectionDesktop__imgMenina {
    width: 700px;
  }
}

@media (min-width: 2100px) {
  .firstSectionDesktop__topTextTitle {
    font-size: 2.8rem;
  }

  .firstSectionDesktop__topText {
    font-size: 1.9rem;
  }

  .firstSectionDesktop__icons {
    margin-left: -180px;
  }

  .firstSectionDesktop__topTextContainer {
    padding: 0 150px;
    height: 500px;
  }

  .firstSectionDesktop__imgContainer {
    top: 10px;
  }
}
