.secondSectionDesktop {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 0;
}

.secondSectionDesktop__title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 300;
  width: 70%;
  margin-bottom: 30px;
}

.comNegrito {
  margin-left: 5px;
  font-weight: 500;
}

.secondSectionDesktop__containerCarinhaBoxe {
  display: flex;
  position: relative;
  width: 100%;
}

.buttonBigger {
  padding: 20px;
  width: 400px;
  height: 100%;
}

.buttonBigger > * {
  font-size: 1.5rem !important;
}

.secondSectionDesktop__divVazia {
  background-color: rgb(31, 30, 30);
  width: 60%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-right: 100px;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
}

.secondSectionDesktop__imgCara {
  object-fit: fill;
  width: 100% !important;
  align-self: center;
  padding: 20px;
}

.secondSectionDesktop__containerCarinhaBoxe_boxes {
  width: 60%;
  margin-left: -120px;
}

@media (max-width: 850px) {
  .secondSectionDesktop {
    display: none;
  }
}

@media (min-width: 1000px) {
  .secondSectionDesktop__imgCara {
    width: 450px;
  }

  .secondSectionDesktop__title {
    font-size: 1.6rem;
  }
}

@media (min-width: 1200px) {
  .secondSectionDesktop__imgCara {
    width: 500px;
  }

  .secondSectionDesktop__title {
    font-size: 1.7rem;
  }
}

@media (min-width: 1300px) {
  .secondSectionDesktop__containerCarinhaBoxe_boxes {
    margin-left: -140px;
  }
}

@media (min-width: 1400px) {
  .secondSectionDesktop__imgCara {
    width: 550px;
  }

  .secondSectionDesktop__divVazia {
    padding-right: 50px;
  }

  .secondSectionDesktop__title {
    font-size: 1.8rem;
  }
}

@media (min-width: 1600px) {
  .secondSectionDesktop__imgCara {
    width: 600px;
  }

  .secondSectionDesktop__title {
    width: 60%;
    font-size: 1.9rem;
  }
}

@media (min-width: 1800px) {
  .secondSectionDesktop__imgCara {
    width: 650px;
  }

  .secondSectionDesktop__divVazia {
    padding-right: 0;
  }

  .secondSectionDesktop__title {
    font-size: 2rem;
  }
}

@media (min-width: 2000px) {
  .secondSectionDesktop__title {
    width: 50%;
    font-size: 2.1rem;
  }
}

@media (min-width: 2200px) {
  .secondSectionDesktop__title {
    font-size: 2.2rem;
    width: 1100px;
  }
}
