.boxeSections {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  box-shadow: 0 0 10px 1px rgba(137, 137, 137, 0.1);
  border-radius: 8px;
  padding: 15px 0;
  margin: 10px;
  background-color: white;
}

.boxeSections__desktop {
  flex-direction: row;
  width: 82%;
  padding: 20px;
  margin: 20px;
}

.boxeSections__img {
  object-fit: contain;
  width: 70px;
}

.boxeSections__containerText {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.boxeSections__containerText > span {
  text-align: start;
  width: 100%;
}

.boxeSections__title {
  width: 75%;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 2px;
  margin-bottom: 5px;
  color: black;
}

.boxeSections__text {
  width: 90%;
  font-size: 0.8rem;
  color: var(--grayInputs);
  text-align: center;
}

@media (min-width: 400px) {
  .boxeSections__img {
    width: 80px;
  }

  .boxeSections__title {
    font-size: 1.1rem;
  }

  .boxeSections__text {
    font-size: 0.9rem;
  }
}

@media (min-width: 500px) {
  .boxeSections__img {
    width: 90px;
  }

  .boxeSections__title {
    font-size: 1.2rem;
  }

  .boxeSections__text {
    font-size: 1rem;
  }
}

@media (min-width: 600px) {
  .boxeSections__img {
    width: 100px;
  }

  .boxeSections__title {
    font-size: 1.3rem;
  }

  .boxeSections__text {
    font-size: 1.1rem;
  }
}

@media (min-width: 700px) {
  .boxeSections__img {
    width: 110px;
  }

  .boxeSections__title {
    font-size: 1.4rem;
  }

  .boxeSections__text {
    font-size: 1.2rem;
  }
}

@media (min-width: 850px) {
  .boxeSections__img {
    width: 70px;
  }

  .boxeSections__title {
    font-size: 1rem;
  }

  .boxeSections__text {
    font-size: 0.8rem;
  }
}

@media (min-width: 1000px) {
  .boxeSections__img {
    width: 80px;
  }

  .boxeSections__title {
    font-size: 1.1rem;
  }

  .boxeSections__text {
    font-size: 0.9rem;
  }
}

@media (min-width: 1200px) {
  .boxeSections__img {
    width: 90px;
  }

  .boxeSections__title {
    font-size: 1.2rem;
  }

  .boxeSections__text {
    font-size: 1rem;
  }
}

@media (min-width: 1300px) {
  .boxeSections__img {
    width: 90px;
  }

  .boxeSections__title {
    font-size: 1.3rem;
  }

  .boxeSections__text {
    font-size: 1.1rem;
  }

  .boxeSections__desktop {
    width: 80%;
  }
}

@media (min-width: 1400px) {
  .boxeSections__img {
    width: 100px;
  }

  .boxeSections__title {
    font-size: 1.3rem;
  }

  .boxeSections__text {
    font-size: 1.1rem;
  }
}

@media (min-width: 1600px) {
  .boxeSections__img {
    width: 110px;
  }

  .boxeSections__title {
    font-size: 1.4rem;
    width: 67%;
  }

  .boxeSections__text {
    font-size: 1.2rem;
  }
}
