.telaObrigado__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: whitesmoke;
}

.telaObrigado {
  margin: 10px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 2px 10px -4px rgba(0, 0, 0, 0.2);
  width: calc(90% - 60px);
  max-width: 400px;
  background-color: white;
}

.telaObrigado__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.telaObrigado__header_title {
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0;
  margin-bottom: 5px;
}

.telaObrigado__header_subtitle {
  opacity: 0.7;
  font-weight: 400;
  font-size: 0.9rem;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.telaObrigado__link {
  color: black;
  cursor: pointer;
}

.telaObrigado__link:hover {
  text-decoration: underline;
}

@media (min-width: 450px) {
  .telaObrigado__header_title {
    font-size: 1.5rem;
  }

  .telaObrigado__header_subtitle {
    font-size: 1rem;
  }
}

@media (min-width: 600px) {
  .telaObrigado {
    padding: 40px 30px;
  }

  .telaObrigado__header_title {
    font-size: 1.6rem;
  }

  .telaObrigado__header_subtitle {
    font-size: 1.1rem;
  }
}

@media (min-width: 1000px) {
  .telaObrigado {
    padding: 40px 30px;
  }

  .telaObrigado__header_title {
    font-size: 1.7rem;
  }

  .telaObrigado__header_subtitle {
    font-size: 1.2rem;
  }
}
