.secondSectionMobal {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px 0;
}

.secondSectionMobal__title {
    text-align: center;
    font-size: 1.15rem;
    font-weight: 300;
    width: 90%;
    margin-bottom: 15px;
}

.comNegrito {
    margin-left: 5px;
    font-weight: 500;
}

.secondSectionMobal__divVazia {
    position: relative;
    background-color: rgb(239, 242, 246);
    height: 170px;
    width: 100%;
    margin-top: 40px;
}

.secondSectionMobal__imgCara {
    object-fit: contain;
    width: 200px;
    position: absolute;
    left: 0;
    top: -66px;

}

@media (min-width: 400px) {
    .secondSectionMobal__title {
        font-size: 1.3rem;
    }

    .secondSectionMobal__divVazia {
        height: 206px;
    }

    .secondSectionMobal__imgCara {
        width: 230px;
    }
}

@media (min-width: 450px) {
    .secondSectionMobal__title {
        width: 85%;
    }

    .secondSectionMobal__divVazia {
        height: 240px;
    }

    .secondSectionMobal__imgCara {
        width: 259px;
    }
}

@media (min-width: 520px) {

    .secondSectionMobal__title {
        font-size: 1.4rem;
    }

    .secondSectionMobal__imgCara {
        width: 260px;
    }
}

@media (min-width: 600px) {

    .secondSectionMobal__title {
        font-size: 1.5rem;
    }

    .secondSectionMobal__imgCara {
        width: 275px;
    }

    .secondSectionMobal__divVazia {
        height: 260px;
    }
}

@media (min-width: 650px) {

    .secondSectionMobal__title {
        font-size: 1.6rem;
    }

}

@media (min-width: 700px) {

    .secondSectionMobal__title {
        font-size: 1.7rem;
    }

    .secondSectionMobal__imgCara {
        width: 290px;
    }

    .secondSectionMobal__divVazia {
        height: 280px;
    }
}

@media (min-width: 800px) {

    .secondSectionMobal__title {
        font-size: 1.8rem;
    }

}


@media (min-width:850px) {
    .secondSectionMobal {
        display: none;
    }
}