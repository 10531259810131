.card {
  background-color: white;
  height: 300px;
  width: 200px;
  box-shadow: 1px 2px 10px -4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.card__popular > .card__header {
  background: var(--secondary);
  color: var(--black);
}

.card__popular > .card__footer > .card__footerButton {
  background: var(--secondary);
  color: var(--black);
}

.card__popular > .card__footer > .card__footerButton:hover {
  background: var(--black);
  background: var(--black-gradient);
  color: var(--secondary);
}

.card__popular {
  border: 3px solid var(--secondary);
}

.card__headerPopular {
  display: none;
}

.card__popular > .card__header > .card__headerPopular {
  display: flex;
  position: absolute;
  top: 0;
  background: var(--black);
  background: var(--black-gradient);
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: var(--secondary);
  font-size: small;
  font-weight: 600;
  align-items: center;
  justify-content: center;
}

.card__header {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 90px;
  background: var(--black);
  background: var(--black-gradient);
  color: var(--secondary);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.card__headerCycle {
  font-size: 2rem;
  font-weight: 600;
}

.card__headerSaves {
  position: absolute;
  top: 60px;
  font-size: x-small;
  font-weight: 500;
}

.card__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 110px;
}

.card__bodyPriceContainer {
  margin-left: 25px;
}

.card__bodyPrice {
  font-size: 2.5rem;
  font-weight: 700;
}

.card__bodyCents {
  font-weight: 700;
}

.card__bodyDescription {
  position: absolute;
  top: 80px;
  font-size: x-small;
  color: gray;
}

.card__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.card__footerButton {
  padding: 10px 30px;
  background: var(--black);
  background: var(--black-gradient);
  color: var(--secondary);
  font-weight: 600;
  cursor: pointer;
  border-radius: 5px;
}

.card__footerButton:hover {
  background: var(--secondary);
  color: black;
}

.card__cifrao {
  font-size: 1.2rem;
  font-weight: 300;
}

.card__mes {
  font-size: 0.9rem;
}
