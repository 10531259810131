.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
  min-height: 100vh;
  min-width: 100vw;
  padding-top: 100px;
}

.home {
  overflow-x: hidden;
}

.home_title{
  font-weight: 600;
  font-size: 1.2rem;
  margin-top: 40px;
}

.home_subtitle{
  font-size: 0.9rem;
}

.home__cardsContainer {
  display: flex;
  width: 660px;
  padding: 20px;
  padding-bottom: 10px;
  justify-content: space-between;
}

.home__button {
  display: flex;
  flex-direction: column;
}

.home__download {
  border: 1.5px solid black;
  background: rgb(18, 18, 18);
  background: linear-gradient(
    90deg,
    rgba(18, 18, 18, 1) 0%,
    rgba(32, 31, 17, 1) 47%,
    rgba(255, 230, 0, 1) 100%
  );
  align-self: center;
  color: rgba(255, 230, 0, 1) !important;
}
.home__versaoAtual {
  font-size: x-small;
  align-self: center;
}

.home__card {
  background-color: white;
  padding: 20px 10px;
  width: 95%;
  border-radius: 5px;
  max-width: 500px;
  box-shadow: 1px 2px 10px -4px rgba(0, 0, 0, 0.2);
}

.home__cardSubtitle {
  font-weight: 600;
}
.home__cardDescription {
  font-weight: 400;
}

.home__cardBasicButton {
  color: var(--secondary);
  background-color: rgba(0, 0, 0, 0.8);
  padding: 5px 10px;
  border-radius: 5px;
  margin: 10px 0;
  cursor: pointer;
  width: 250px;
  text-align: center;
}

.home__cardBasicButton:hover {
  filter: brightness(1.1);
}

.home__cardRedefinir {
  color: var(--secondary);
  background-color: rgba(0, 0, 0, 0.8);
}

.home__cardRedefinir:hover {
  opacity: 0.9;
}
.home__cardPortal {
  color: rgba(0, 0, 0, 1);
  font-weight: 500;
  background-color: var(--secondary);
}

.home__cardUserInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.home__container {
  top: 0;
  z-index: 100;
  display: flex;
  position: fixed;
  padding: 18px 15px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 3px 1px rgba(211, 211, 211, 0.3);
}

.home__esquerda {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home__esquerda_logo {
  object-fit: contain;
  width: 30px;
  border-radius: 5px;
}

.home__esquerda_span {
  color: rgba(0, 0, 0, 1);
  font-size: 1rem;
  font-weight: 500;
  margin-left: 5px;
}

.home__direita {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
  position: relative;
}

.home__direita_nome {
  font-weight: 500;
  color: black;
}

.home__direita_linkContainer {
  position: absolute;
  top: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.home__direita_botaoSair {
  text-align: right;
  cursor: pointer;
  color: black;
  font-weight: 600;
}

.home__direita_botaoSair:hover {
  color: gray;
}

@media (min-width: 400px) {
  .home__direita {
    font-size: 0.8rem;
  }
}

@media (min-width: 500px) {
  .home_title{
    font-size: 1.3rem;
  }
  
  .home_subtitle{
    font-size: 1rem;
  }

  .home__esquerda_logo {
    width: 35px;
  }

  .home__esquerda_span {
    font-size: 1rem;
  }

  .home__direita {
    font-size: 0.9rem;
  }

  .home__direita_botaoSair {
    top: 18px;
  }
}

@media (min-width: 900px) {
  .home {
    padding: 20px;
    padding-top: 120px;
    width: 100%;
  }

  .home__esquerda_logo {
    width: 40px;
  }

  .home__esquerda_span {
    font-size: 1.1rem;
  }

  .home__direita {
    font-size: 1rem;
  }

  .home__direita_botaoSair {
    top: 20px;
  }
}

@media (min-width: 1400px) {
  
  .home_title{
    font-size: 1.4rem;
  }

  .home__esquerda_logo {
    width: 45px;
  }

  .home__esquerda_span {
    font-size: 1.2rem;
    margin-left: 10px;
  }

  .home__direita {
    font-size: 1.1rem;
  }

  .home__direita_botaoSair {
    top: 20px;
  }
}

@media (min-width: 1800px) {
  .home {
    padding: 25px 30px;
    width: 100%;
  }

  .home_title{
    font-size: 1.5rem;
  }

  .home__esquerda_logo {
    width: 50px;
  }

  .home__esquerda_span {
    font-size: 1.4rem;
    margin-left: 10px;
  }

  .home__direita {
    font-size: 1.3rem;
  }

  .home__direita_botaoSair {
    top: 25px;
  }
}

@media (max-width: 660px) {
  .home__cardsContainer {
    width: 330px;
    flex-direction: column;
  }

  .home__cardsContainer > * {
    margin-bottom: 10px;
    align-self: center;
  }

}
