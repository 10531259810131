.telaEmailRecuperacao__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: whitesmoke;
}

.telaEmailRecuperacao {
  margin: 10px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 2px 10px -4px rgba(0, 0, 0, 0.2);
  width: calc(90% - 60px);
  max-width: 500px;
  background-color: white;
}

.telaEmailRecuperacao__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.telaEmailRecuperacao__header_title {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0;
  margin-bottom: 5px;
}

.telaEmailRecuperacao__header_subtitle {
  opacity: 0.7;
  font-weight: 400;
  font-size: 0.8rem;
  text-align: center;
}

.telaEmailRecuperacao__inputsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.telaEmailRecuperacao__inputsContainer > * {
  margin-top: 15px !important;
}

.telaEmailRecuperacao__inputsContainer_input {
  border: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  padding: 0;

  font-size: 0.7rem;
  font-weight: 500;
  border: 1px solid lightgray;
  border-radius: 5px;
  height: 35px;
  padding: 0 10px;
  color: gray;
  width: calc(100% - 20px);
}

.telaEmailRecuperacao__inputsContainer_input:focus {
  outline: none;
}

.telaEmailRecuperacao__button {
  margin-top: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 5px 0;
  color: white;
  background-color: black;
  font-size: 0.8rem;
  cursor: pointer;
}

.telaEmailRecuperacao__button:hover {
  background-color: var(--roxo-transparente);
}

.telaEmailRecuperacao__textoLinkBottom {
  font-weight: 400;
  font-size: 0.65rem;
  padding: 5px 8px;
  display: flex;
  justify-content: center;
  width: 90%;
  max-width: 560px;
}

.telaEmailRecuperacao__linkBottom {
  color: black;
  cursor: pointer;
}

.telaEmailRecuperacao__linkBottom:hover {
  text-decoration: underline;
}
.telaEmailRecuperacao__textoLinkBottom > .telaEmailRecuperacao__linkBottom {
  margin-left: 5px;
}

.telaEmailRecuperacao__span {
  margin-right: 5px;
}

.telaEmailRecuperacao__success {
  color: green;
}
.telaEmailRecuperacao__error {
  color: red;
}

@media (min-width: 450px) {
  .telaEmailRecuperacao__header_title {
    font-size: 1.2rem;
  }

  .telaEmailRecuperacao__header_subtitle {
    font-size: 0.9rem;
  }

  .telaEmailRecuperacao__inputsContainer_input {
    font-size: 0.8rem;
    height: 40px;
    width: 100%;
  }

  .telaEmailRecuperacao__textoLinkBottom {
    font-size: 0.75rem;
  }

  .telaEmailRecuperacao__button {
    margin-top: 15px;
    font-size: 0.9rem;
  }
}

@media (min-width: 600px) {
  .telaEmailRecuperacao {
    padding: 40px 30px;
  }

  .telaEmailRecuperacao__header_title {
    font-size: 1.25rem;
  }

  .telaEmailRecuperacao__header_subtitle {
    font-size: 0.95rem;
  }

  .telaEmailRecuperacao__inputsContainer_input {
    font-size: 0.9rem;
  }

  .telaEmailRecuperacao__textoLinkBottom {
    font-size: 0.9rem;
  }

  .telaEmailRecuperacao__button {
    font-size: 0.95rem;
  }
}

@media (min-width: 1000px) {
  .telaEmailRecuperacao {
    padding: 40px 30px;
  }

  .telaEmailRecuperacao__header_title {
    font-size: 1.35rem;
  }

  .telaEmailRecuperacao__header_subtitle {
    font-size: 1rem;
  }

  .telaEmailRecuperacao__inputsContainer_input {
    font-size: 1rem;
    height: 45px;
  }

  .telaEmailRecuperacao__textoLinkBottom {
    font-size: 0.95rem;
  }

  .telaEmailRecuperacao__button {
    font-size: 1rem;
    padding: 7px 0;
  }
}

@media (max-width: 500px) {
  .telaEmailRecuperacao {
    padding: 20px 10px;
    width: 95%;
  }

  .telaEmailRecuperacao__inputsContainer_input {
    width: 100%;
  }
}
