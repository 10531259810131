.firstSectionMobal {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.firstSectionMobal__textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 25px;
}

.firstSectionMobal__textTitle {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 10px;
}

.semNegrito {
  margin-left: 5px;
  font-weight: 300;
}

.firstSectionMobal__text {
  font-size: 0.8rem;
  font-weight: 300;
  color: var(--grayInputs);
}

.firstSectionMobal__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--black);
  background: var(--black-gradient);
  margin-top: 40px;
  width: 100%;
}

.firstSectionMobal__imgContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.firstSectionMobal__icons {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -30px;
}

.firstSectionMobal__imgLampada {
  object-fit: contain;
  width: 50px;
  margin-top: 80px;
}

.firstSectionMobal__imgAviao {
  object-fit: contain;
  width: 50px;
  margin-bottom: 40px;
}

.firstSectionMobal__imgBonequinhos {
  object-fit: contain;
  width: 50px;
  margin-top: 80px;
}

.firstSectionMobal__imgMenina {
  object-fit: contain;
  width: 200px;
}

.firstSectionMobal__buttonsCorte {
  margin-bottom: -1px;
  margin-left: -1px;
  margin-right: -1px;
}

@media (min-width: 350px) {
  .firstSectionMobal__textContainer {
    padding: 0 30px;
  }
}

@media (min-width: 400px) {
  .firstSectionMobal__textTitle {
    font-size: 1.3rem;
  }

  .firstSectionMobal__text {
    font-size: 0.9rem;
  }

  .firstSectionMobal__imgLampada {
    width: 55px;
  }

  .firstSectionMobal__imgAviao {
    width: 55px;
  }

  .firstSectionMobal__imgBonequinhos {
    width: 55px;
  }

  .firstSectionMobal__imgMenina {
    width: 230px;
  }
}

@media (min-width: 450px) {
  .firstSectionMobal__imgLampada {
    width: 60px;
  }

  .firstSectionMobal__imgAviao {
    width: 60px;
  }

  .firstSectionMobal__imgBonequinhos {
    width: 60px;
  }

  .firstSectionMobal__textContainer {
    padding: 0 40px;
  }
}

@media (min-width: 520px) {
  .firstSectionMobal__textTitle {
    font-size: 1.4rem;
  }

  .firstSectionMobal__text {
    font-size: 0.95rem;
  }

  .firstSectionMobal__imgLampada {
    width: 60px;
  }

  .firstSectionMobal__imgAviao {
    width: 60px;
    margin-bottom: 80px;
  }

  .firstSectionMobal__imgBonequinhos {
    width: 60px;
  }

  .firstSectionMobal__imgMenina {
    width: 250px;
  }

  .firstSectionMobal__icons {
    width: 70%;
    margin-bottom: -30px;
  }
}

@media (min-width: 550px) {
  .firstSectionMobal__textContainer {
    padding: 0 50px;
  }
}

@media (min-width: 600px) {
  .firstSectionMobal__textTitle {
    font-size: 1.5rem;
  }

  .firstSectionMobal__text {
    font-size: 1rem;
  }

  .firstSectionMobal__imgLampada {
    width: 70px;
  }

  .firstSectionMobal__imgAviao {
    width: 70px;
  }

  .firstSectionMobal__imgBonequinhos {
    width: 70px;
  }

  .firstSectionMobal__imgMenina {
    width: 280px;
  }

  .firstSectionMobal__textContainer {
    padding: 0 60px;
  }
}

@media (min-width: 650px) {
  .firstSectionMobal__textTitle {
    font-size: 1.6rem;
  }

  .firstSectionMobal__text {
    font-size: 1.1rem;
  }

  .firstSectionMobal__imgLampada {
    width: 75px;
  }

  .firstSectionMobal__imgAviao {
    width: 75px;
  }

  .firstSectionMobal__imgBonequinhos {
    width: 75px;
  }

  .firstSectionMobal__imgMenina {
    width: 290px;
  }
}

@media (min-width: 700px) {
  .firstSectionMobal__textTitle {
    font-size: 1.7rem;
  }

  .firstSectionMobal__text {
    font-size: 1.2rem;
  }

  .firstSectionMobal__imgLampada {
    width: 80px;
  }

  .firstSectionMobal__imgAviao {
    width: 80px;
  }

  .firstSectionMobal__imgBonequinhos {
    width: 80px;
  }

  .firstSectionMobal__imgMenina {
    width: 300px;
  }

  .firstSectionMobal__textContainer {
    padding: 0 70px;
  }

  .firstSectionMobal__icons {
    width: 60%;
  }
}

@media (min-width: 800px) {
  .firstSectionMobal__textTitle {
    font-size: 1.8rem;
  }
}

@media (min-width: 850px) {
  .firstSectionMobal {
    display: none;
  }
}
